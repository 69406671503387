@use '@angular/material' as mat;
@import "tailwindcss/base";





.btn {
  @apply rounded-md  py-3 px-4 font-medium transition-all ease-in-out delay-200 text-center;

  &:hover{
    @apply rounded-bl-none rounded-tr-none;
  }

  &.btn-secondary {
    @apply text-primary bg-secondaryBtn;

    &:hover{
      @apply bg-secondaryhover
    }
  }

  &.btn-primary {
    @apply text-white bg-primary;

    &:hover{
      @apply bg-primaryHover;
    }
  }
}
@import "tailwindcss/components";

.main-grid {
  @apply grid grid-cols-4 lg:grid-cols-12 gap-x-2 gap-y-2 py-16 lg:py-32;
}

@import "tailwindcss/utilities";

html, body { height: 100%; }
ul { list-style-type: none; padding: 0; }

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
}

.top-bar-nav-font {
  @apply text-primary font-medium;

  &:hover {
    text-decoration: underline;
  }
}

.download-icon {
  @apply cursor-pointer  h-5 transition-all  duration-200  ease-out  filter  saturate-0 hover:saturate-100;
}

.card-title {
  @apply font-normal text-4xl font-semibold;
}

.introducing-fossity-card-tittle{
  @apply text-cardTitle font-semibold
}

:root{
  --primary: #f1f1f1;
  --accent: #307FE2;
}

